import { ProductsPageCheckout } from '@trmediaab/site-offers';
import theme from '@trmediaab/theme-jokersystemet';
import { Text, ThemeProvider } from '@trmediaab/zebra';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import PageBody from 'shared/components/layout/PageBody';
import PageMeta from 'shared/components/PageMeta';
import withContentMenu from 'shared/containers/withContentMenu';
import { getText } from 'shared/utils/Text';

const CheckoutPage = ({ campaignId, sku, orderId }) => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigateToProductsList = useCallback(() => {
    history.push(`/abonnemang`);
  }, [history]);

  return (
    <PageBody alignTo="contentStart">
      <PageMeta theme="article" title={getText('menu-register')} />

      <ThemeProvider theme={theme}>
        <Text.root>
          <ProductsPageCheckout
            campaign={campaignId}
            sku={sku}
            orderId={orderId}
            isSubscription
            onCancel={navigateToProductsList}
            onCheckoutAuthentication={() => {}}
          />
        </Text.root>
      </ThemeProvider>
    </PageBody>
  );
};

CheckoutPage.propTypes = {
  isLoggedIn: PropTypes.bool,
  campaignId: PropTypes.string,
  sku: PropTypes.string,
  orderId: PropTypes.string,
};

export default withContentMenu(CheckoutPage);
