import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { bindActionCreators } from 'redux';

import SiteMessages from 'shared/components/SiteMessages';
import { NAVIGATOR_OFFLINE, PROMO } from 'shared/constants/MessageTypes';
import {
  dismissSiteMessageByClientId,
  dismissSiteMessageByServerId,
  dismissSiteMessageByType,
  fetchAlert,
  fetchPromo,
} from 'shared/redux/actions';
import { ui, user } from 'shared/redux/selectors';

const mapStateToProps = state => ({
  isLoggedIn: user.isLoggedIn(state),
  messages: ui.getUnseenSiteMessages(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dismissSiteMessageByServerId,
      dismissSiteMessageByClientId,
      dismissSiteMessageByType,
      fetchPromo,
      fetchAlert,
    },
    dispatch,
  );

const SiteMessagesContainer = ({
  messages,
  isLoggedIn,
  fetchPromo,
  fetchAlert,
  dismissSiteMessageByType,
  dismissSiteMessageByServerId,
  dismissSiteMessageByClientId,
}) => {
  const { pathname } = useLocation();

  const onCloseClick = message => {
    const { id } = message;
    if (id) {
      dismissSiteMessageByServerId(message);
    } else {
      dismissSiteMessageByClientId(message);
    }
  };

  useEffect(() => {
    fetchPromo();

    // Poll for alert messages. In development this only works when
    // using `vercel dev` since we are using a serverless function.
    let pollAlertsInterval;
    if (
      process.env.NODE_ENV !== 'development' ||
      process.env.REACT_APP_VERCEL_DEV === 'true'
    ) {
      fetchAlert();
      pollAlertsInterval = setInterval(
        fetchAlert,
        process.env.REACT_APP_ALERTS_POLL_INTERVAL,
      );
    }

    const onOnline = () => dismissSiteMessageByType(NAVIGATOR_OFFLINE);

    window.addEventListener('online', onOnline);

    return () => {
      window.removeEventListener('online', onOnline);
      clearInterval(pollAlertsInterval);
    };
  }, [dismissSiteMessageByType, fetchAlert, fetchPromo]);

  const filteredMessages = isLoggedIn
    ? messages.filter(message => message.messageType !== PROMO)
    : messages;

  // Note: remounts on pathname changes to ensure login links are using
  // an up to date location.href value
  return (
    <SiteMessages
      key={pathname}
      messages={filteredMessages}
      onClose={onCloseClick}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SiteMessagesContainer);
