import { ProductsPage } from '@trmediaab/site-offers';
import theme from '@trmediaab/theme-jokersystemet';
import { Text, ThemeProvider } from '@trmediaab/zebra';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import PageBody from 'shared/components/layout/PageBody';
import PageMeta from 'shared/components/PageMeta';
import withContentMenu from 'shared/containers/withContentMenu';
import { getText } from 'shared/utils/Text';
import { getUrl } from 'shared/utils/urls';

import Loader from '../loader/Loader';

const SubscriptionsPage = ({ isLoggedIn, offers }) => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigateToSelectedProduct = useCallback(
    (sku, product) => {
      // We assume that the product priced at 0 is Joker Sport. This
      // product shouldn't go through the checkout process.
      // TODO - Hit checkout-api to register subscription on user?
      if (product.price === 0) {
        if (isLoggedIn) {
          history.push('/');
        } else {
          window.location.href = getUrl.register('/');
        }
      } else {
        history.push(`/checkout/${sku}`);
      }
    },
    [history, isLoggedIn],
  );

  const navigateToSelectedCampaign = useCallback(
    campaignId => {
      history.push(`/checkout/${campaignId}`);
    },
    [history],
  );

  return (
    <PageBody alignTo="contentStart">
      <PageMeta theme="article" title={getText('menu-register')} />

      <Loader
        cover
        labelProp="main-loader-offers-heading"
        visible={offers.isLoading}
      />

      {offers.data != null && (
        <ThemeProvider theme={theme}>
          <Text.root>
            <ProductsPage
              authUrl={getUrl.login()}
              registerUrl={getUrl.register()}
              isAuthenticated={isLoggedIn}
              offersData={offers.data}
              site={process.env.REACT_APP_TRAIS_SITE}
              onProductSelected={navigateToSelectedProduct}
              onCampaignSelected={navigateToSelectedCampaign}
            />
          </Text.root>
        </ThemeProvider>
      )}
    </PageBody>
  );
};

SubscriptionsPage.propTypes = {
  isLoggedIn: PropTypes.bool,
  offers: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    data: PropTypes.shape({
      headline: PropTypes.string.isRequired,
      preamble: PropTypes.string.isRequired,
    }),
  }),
};

export default withContentMenu(SubscriptionsPage);
