import { apiMap, getBackendPathForPort } from 'apiMap';

let backendHost = apiMap.default;
let backendPath = 'app/rest';

if (process.env.REACT_APP_BUILD_TARGET === 'stage') {
  try {
    let overridenHost;

    // First try backend override from url param
    const paramsBackend = new URLSearchParams(document.location.search).get(
      'backend',
    );
    if (paramsBackend != null) {
      window.localStorage.setItem('backend', paramsBackend);
      overridenHost = paramsBackend;
    }

    // Secondary check local storage
    overridenHost = overridenHost || window.localStorage.getItem('backend');

    if (overridenHost != null) {
      backendHost = apiMap[overridenHost] || apiMap.default;
    }
  } catch {
    // Ignore
  }
}

if (['stage', 'dev'].includes(process.env.REACT_APP_BUILD_TARGET)) {
  const hostUrl = new URL(backendHost);
  backendPath = getBackendPathForPort(hostUrl.port);
}

// REACT_APP_VERSION is set by CircleCI (production) builds
// On Vercel builds use their system variables
// On localhost a static string
let appVersion = process.env.REACT_APP_VERSION;
if (appVersion == null) {
  appVersion =
    process.env.REACT_APP_VERCEL_ENV != null
      ? `${process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA}.${process.env.REACT_APP_VERCEL_ENV}`
      : 'dev.localhost';
}

const config = {
  backendHost,
  jokerApi: `/${backendPath}`,
  socketUrl: `${backendHost}/${process.env.REACT_APP_SOCKET_PATH}`,
  vercelApi: '/api',
  authApi: `/auth`,
  checkoutApi: '/api/checkout',
  trsportApi:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_TRSPORT_API
      : '/trsport', // Proxied, see setupProxy.js
  traisApi:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_TRAIS_API
      : '/trais', // Proxied, see setupProxy.js
  bugsnagApiKey: process.env.REACT_APP_BUGSNAG_KEY,
  appVersion,
  amplitudeApiKey: process.env.REACT_APP_AMPLITUDE_KEY,
  trMediaApiKey: process.env.REACT_APP_TRMEDIA_KEY,
  youtubeApiKey: process.env.REACT_APP_YOUTUBE_KEY,
  youtubePlaylistAtg: process.env.REACT_APP_YOUTUBE_PLAYLIST_ATG,
  youtubePlaylistSport: process.env.REACT_APP_YOUTUBE_PLAYLIST_SPORT,
  registerUrl: `${process.env.REACT_APP_AUTH_URL}/register?returnUrl=https://www.jokersystemet.se`,
  spelvardeCheckoutUrl: process.env.REACT_APP_SPELVARDE_CHECKOUT_URL,
  travogatCheckoutUrl: process.env.REACT_APP_TRAVOGAT_CHECKOUT_URL,
  storavinsterCheckoutUrl: process.env.REACT_APP_STORAVINSTER_CHECKOUT_URL,
  affiliateUrl:
    'http://clk.tradedoubler.com/click?p=295103&a=3165491&g=24466066&url={url}',
  authUrl: process.env.REACT_APP_AUTH_URL,
  userpagesUrl: process.env.REACT_APP_USERPAGES_URL,
  rikstotoAgentId: '00736',
};

export default config;
