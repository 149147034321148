import { lazy, Suspense } from 'react';

const CalendarComponent = lazy(() =>
  import(/* webpackChunkName: "calendar" */ 'calendar'),
);

const AsyncCalendarApp = () => (
  <Suspense fallback={<span />}>
    <CalendarComponent />
  </Suspense>
);

export default AsyncCalendarApp;
