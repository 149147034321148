import { object } from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';

import Menu from 'shared/components/menu/Menu';
import VisibilityToggle from 'shared/components/VisibilityToggle';
import { categories, items } from 'shared/constants/MenuConstants';
import {
  clearSelectedMenuItem,
  setSelectedMenuItem,
  showAtgSettingsDialog,
  showLiveNoAliasDialog,
  toggleLiveParticipation,
} from 'shared/redux/actions';
import { dialogs, menu, user } from 'shared/redux/selectors';
import { getUrl } from 'shared/utils/urls';

const itemsData = Object.keys(items).map(id => {
  let labelProp, path;
  switch (id) {
    case items.LOGIN:
      labelProp = 'menu-log-in';
      path = getUrl.login();
      break;
    case items.TRMEDIA_ACCOUNT:
      labelProp = 'menu-my-account';
      path = getUrl.userpages();
      break;
    case items.SUBSCRIPTION:
      labelProp = 'menu-subscription';
      path = '/abonnemang';
      break;
    case items.LOGOUT:
      labelProp = 'menu-log-out';
      path = getUrl.logout();
      break;
    case items.REGISTER:
      labelProp = 'menu-register';
      path = '/abonnemang';
      break;
    case items.ABOUT:
      labelProp = 'menu-about';
      path = '/om-joker';
      break;
    case items.FAQ:
      labelProp = 'menu-faq';
      path = '/faq';
      break;
    case items.TIPS:
      labelProp = 'menu-tips';
      path = '/tips';
      break;
    case items.INSTRUCTIONS:
      labelProp = 'menu-instructions';
      path = getUrl.instructions();
      break;
    case items.CONTACT:
      labelProp = 'menu-contact';
      path = '/kontakt';
      break;
    case items.LIVE:
      labelProp = 'menu-live';
      path = getUrl.live();
      break;
    case items.CALENDAR:
      labelProp = 'menu-new';
      path = '/#calendar';
      break;
    case items.ATG_ARCHIVE:
      labelProp = 'menu-archive';
      break;
    case items.ATG_REPORT:
      labelProp = 'menu-report';
      break;
    case items.ATG_COUPONS:
      labelProp = 'menu-coupons';
      break;
    case items.ATG_EXPORT:
      labelProp = 'menu-export';
      break;
    case items.ATG_MANUAL:
      labelProp = 'menu-update';
      break;
    case items.ATG_SETTINGS:
      labelProp = 'menu-settings';
      break;
    case items.SPORT_ARCHIVE:
      labelProp = 'menu-archive';
      break;
    case items.SPORT_EXPORT:
      labelProp = 'menu-export';
      break;
    case items.ACCOUNT:
      labelProp = 'menu-account';
      break;
    case items.NEWS:
      labelProp = 'menu-news';
      path = getUrl.news();
      break;
    default:
      throw new Error(`Add a case for ${id}`);
  }
  return { id, labelProp, path };
});

const menus = Object.keys(categories).reduce((acc, category) => {
  let filter;
  switch (category) {
    case categories.CONTENT:
      filter = [
        items.CALENDAR,
        items.TIPS,
        items.LIVE,
        items.ABOUT,
        items.FAQ,
        items.INSTRUCTIONS,
        items.CONTACT,
        items.ATG_SETTINGS,
        items.NEWS,
      ];
      break;
    case categories.ATG:
      filter = [
        items.CALENDAR,
        items.TIPS,
        items.LIVE,
        items.ATG_ARCHIVE,
        items.ATG_REPORT,
        items.ATG_COUPONS,
        items.ATG_EXPORT,
        items.ATG_MANUAL,
        items.ATG_SETTINGS,
        items.INSTRUCTIONS,
        items.NEWS,
      ];
      break;
    case categories.SPORT:
      filter = [
        items.CALENDAR,
        items.TIPS,
        items.LIVE,
        items.SPORT_ARCHIVE,
        items.INSTRUCTIONS,
        items.SPORT_EXPORT,
        items.ATG_SETTINGS,
        items.NEWS,
      ];
      break;
    default:
      break;
  }

  filter = [
    ...filter,
    items.REGISTER,
    items.SUBSCRIPTION,
    items.TRMEDIA_ACCOUNT,
    items.ACCOUNT,
    items.LOGIN,
    items.LOGOUT,
  ];

  const filtered = [
    ...itemsData
      .filter(data => filter.includes(data.id))
      .sort((a, b) => filter.indexOf(a.id) - filter.indexOf(b.id)),
    { id: 'burger', labelProp: 'menu-burger' },
  ];

  acc[category] = filtered;
  return acc;
}, {});

const mapStateToProps = state => {
  const fullName = user.getFirstname(state)
    ? `${user.getFirstname(state)} ${user.getLastname(state)}`
    : undefined;
  const selectedId = menu.getSelectedId(state);

  return {
    alias: user.getAlias(state),
    category: menu.getCategory(state),
    disabledIds: menu.getDisabledIds(state),
    email: user.getEmail(state),
    fullName,
    imageId: user.getImageId(state),
    isLoggedIn: user.isLoggedIn(state),
    isSelectedDialog: dialogs.hasDialog(state, {
      dialogType: selectedId, // TODO - assumes dialog types = menu item
    }),
    isSubscriber: user.hasSubscription(state),
    selectedMenuId: menu.getSelectedId(state),
    selectedId,
    showManualUpdateButton: menu.manualUpdateButtonActive(state),
    liveParticipant: user.getLiveParticipationState(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearSelectedMenuItem,
      setSelectedMenuItem,
      toggleLiveParticipation,
      showLiveNoAliasDialog,
      showAtgSettingsDialog,
    },
    dispatch,
  );

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(
  class MenuContainer extends Component {
    static propTypes = {
      history: object.isRequired,
      match: object.isRequired,
      location: object.isRequired,
    };

    state = {
      items: [],
    };

    componentDidMount() {
      this.populateState(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (!nextProps.isSelectedDialog && this.props.isSelectedDialog) {
        this.props.clearSelectedMenuItem();
      }
      if (
        nextProps.selectedMenuId === items.ATG_SETTINGS &&
        nextProps.selectedMenuId !== this.props.selectedMenuId
      ) {
        nextProps.showAtgSettingsDialog();
      }
      if (
        nextProps.category !== this.props.category ||
        nextProps.isLoggedIn !== this.props.isLoggedIn ||
        nextProps.showManualUpdateButton !== this.props.showManualUpdateButton
      ) {
        this.populateState(nextProps);
      }
    }

    populateState(props) {
      const { isLoggedIn, category, showManualUpdateButton, isSubscriber } =
        props;
      if (category) {
        this.setState({
          items: menus[category].filter(item => {
            if (item.id === items.REGISTER) {
              return !isLoggedIn;
            }
            if (item.id === items.LOGIN) {
              return !isLoggedIn;
            }
            if (item.id === items.ATG_SETTINGS) {
              return (
                isSubscriber || (ALLOW_FREEMIUM_HORSE_BETTING && isLoggedIn)
              );
            }
            if (item.id === items.LOGOUT) {
              return isLoggedIn;
            }
            if (item.id === items.TRMEDIA_ACCOUNT) {
              return isLoggedIn;
            }
            if (item.id === items.SUBSCRIPTION) {
              return isLoggedIn;
            }
            if (item.id === items.ACCOUNT) {
              return isLoggedIn;
            }
            if (item.id === items.ATG_EXPORT) {
              return !showManualUpdateButton;
            }
            if (item.id === items.ATG_MANUAL) {
              return showManualUpdateButton;
            }
            return true;
          }),
        });
      }
    }

    onMenuItemClick = (event, item) => {
      this.props.setSelectedMenuItem(item.id);
    };

    render() {
      const {
        alias,
        category,
        disabledIds,
        email,
        fullName,
        imageId,
        liveParticipant,
        isLoggedIn,
        isSubscriber,
        selectedId,
        toggleLiveParticipation,
        showLiveNoAliasDialog,
      } = this.props;
      const { items } = this.state;

      return (
        <VisibilityToggle
          render={({
            insideRefHandler: mobileMenuRefsHandler,
            onToggleClick: toggleMobileMenuHandler,
            visible: mobileMenuVisible,
          }) => (
            <Menu
              alias={alias}
              category={category}
              clickHandler={this.onMenuItemClick}
              disabledIds={disabledIds}
              email={email}
              fullName={fullName}
              imageId={imageId}
              isLoggedIn={isLoggedIn}
              isMobileMenuVisible={mobileMenuVisible}
              isSubscriber={isSubscriber}
              items={items}
              mobileMenuRefsHandler={mobileMenuRefsHandler}
              selectedId={selectedId}
              toggleLiveParticipation={toggleLiveParticipation}
              showDialog={showLiveNoAliasDialog}
              toggleMobileMenuHandler={toggleMobileMenuHandler}
              liveParticipant={liveParticipant}
            />
          )}
        />
      );
    }
  },
);
