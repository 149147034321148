import { lazy, Suspense } from 'react';

const ArticleComponent = lazy(() =>
  import(/* webpackChunkName: "article" */ 'article'),
);

const AsyncArticleApp = () => (
  <Suspense fallback={<span />}>
    <ArticleComponent />
  </Suspense>
);

export default AsyncArticleApp;
