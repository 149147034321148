import classnames from 'classnames';
import isString from 'lodash/isString';
import noop from 'lodash/noop';
import { bool, func, string } from 'prop-types';
import { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { items } from 'shared/constants/MenuConstants';
import { menuItem } from 'shared/proptypes';
import * as Text from 'shared/utils/Text';

import Button from '../../button/Button';
import CheckCircle from '../../icon/CheckCircle';
import Update from '../../icon/Update';
import AccountItem from '../AccountItem';
import BurgerButton from '../BurgerButton';
import styles from './MenuItem.module.scss';

export default class MenuItem extends Component {
  static propTypes = {
    item: menuItem.isRequired,
    disabled: bool.isRequired,
    selected: bool.isRequired,
    clickHandler: func.isRequired,
    popupVisible: bool.isRequired,
    refHandler: func,
    className: string,
  };

  static defaultProps = {
    refHandler: noop,
  };

  onClick = e => {
    const { item, disabled, clickHandler } = this.props;

    if (disabled) {
      e.preventDefault();
      return;
    } else {
      // Calendar link already performs a calendar anchor scroll after render
      // Other links should scroll to top
      if (item.id !== items.CALENDAR) {
        window.scrollTo(0, 0);
      }

      clickHandler(e, item);
    }
  };

  render() {
    const {
      item: { id, path, labelProp },
      disabled,
      selected,
      popupVisible,
      refHandler,
      className: additionalClassName,
    } = this.props;

    let button;
    const className = classnames(styles.menuButton, {
      disabled,
      active: selected && id !== items.TRMEDIA_ACCOUNT,
    });
    const buttonChildren = (
      <div className={styles.menuButtonInner}>
        {(id === items.ATG_EXPORT || id === items.SPORT_EXPORT) && (
          <CheckCircle />
        )}
        {id === items.ATG_MANUAL && <Update />}
        {id === items.ACCOUNT ? (
          <AccountItem />
        ) : (
          // eslint-disable-next-line css-modules/no-undef-class
          <span className={styles.title}>
            {labelProp && Text.get(labelProp)}
          </span>
        )}
      </div>
    );

    if (id === 'burger') {
      button = <BurgerButton onClick={this.onClick} active={popupVisible} />;
    } else {
      if (!isString(path)) {
        button = (
          <Button onClick={this.onClick} className={className}>
            {buttonChildren}
          </Button>
        );
      } else if (path.indexOf('http') === 0) {
        button = (
          <a
            href={path}
            onClick={this.onClick}
            className={className}
            target={
              path.startsWith(process.env.REACT_APP_AUTH_URL)
                ? undefined
                : '_blank'
            }
            rel="noopener noreferrer"
          >
            {buttonChildren}
          </a>
        );
      } else {
        button = (
          <NavLink
            exact={id === items.CALENDAR}
            to={path}
            onClick={this.onClick}
            className={className}
          >
            {buttonChildren}
          </NavLink>
        );
      }
    }

    return (
      <li
        ref={refHandler}
        className={classnames(
          styles.menuItem,
          additionalClassName,
          `menu-item--${id.toLowerCase()}`,
        )}
      >
        {button}
      </li>
    );
  }
}
