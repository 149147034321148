export const categories = {
  CONTENT: 'CONTENT',
  ATG: 'ATG',
  SPORT: 'SPORT',
};

export const items = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  REGISTER: 'REGISTER',
  TRMEDIA_ACCOUNT: 'TRMEDIA_ACCOUNT',
  ACCOUNT: 'ACCOUNT',
  ABOUT: 'ABOUT',
  FAQ: 'FAQ',
  INSTRUCTIONS: 'INSTRUCTIONS',
  CONTACT: 'CONTACT',
  NEWS: 'NEWS',
  CALENDAR: 'CALENDAR',
  ATG_ARCHIVE: 'ATG_ARCHIVE',
  ATG_REPORT: 'ATG_REPORT',
  ATG_COUPONS: 'ATG_COUPONS',
  ATG_EXPORT: 'ATG_EXPORT',
  ATG_MANUAL: 'ATG_MANUAL',
  ATG_SETTINGS: 'ATG_SETTINGS',
  SPORT_ARCHIVE: 'SPORT_ARCHIVE',
  SPORT_EXPORT: 'SPORT_EXPORT',
  SUBSCRIPTION: 'SUBSCRIPTION',
  LIVE: 'LIVE',
};

if (INCLUDE_TIPS) {
  items.TIPS = 'TIPS';
}
