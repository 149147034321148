import classnames from 'classnames';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import ExternalLink from 'shared/components/ExternalLink';
import * as types from 'shared/constants/MessageTypes';
import { getText } from 'shared/utils/Text';
import { getUrl } from 'shared/utils/urls';

import Button from './button/Button';
import CloseButton from './button/CloseButton';
import Editorial from './Editorial';
import HTMLContent from './HTMLContent';
import Info from './icon/Info';
import Warning from './icon/Warning';
import ContentWrapper from './layout/ContentWrapper';

const renderMessageText = ({ messageType, message: text }) => {
  if (messageType === types.SESSION_EXPIRED) {
    return getText('site-message-session');
  }

  if (messageType === types.LOGGED_IN_TO_OTHER_DEVICE) {
    return getText('site-message-other-device');
  }

  if (messageType === types.USER_LOGGED_OUT_BY_SERVER) {
    return getText('site-message-logged-out-by-server');
  }

  if (messageType === types.NAVIGATOR_OFFLINE) {
    return getText('site-message-network');
  }

  if (messageType === types.UPDATE_AVAILABLE) {
    return getText('site-message-update');
  }

  if (messageType === types.ALERT) {
    return <HTMLContent className="html-message" content={text} />;
  }

  if (messageType === types.PROMO) {
    return text;
  }

  return text || getText('error-message-generic');
};

const renderMessageLink = ({ messageType, ...messageProps }, onClose) => {
  if (
    [
      types.SESSION_EXPIRED,
      types.LOGGED_IN_TO_OTHER_DEVICE,
      types.USER_LOGGED_OUT_BY_SERVER,
    ].includes(messageType)
  ) {
    return (
      <a
        href={getUrl.login()}
        onClick={() => onClose()}
        className="message-link"
      >
        {getText('login-button')}
      </a>
    );
  }

  if (messageType === types.UPDATE_AVAILABLE) {
    const { swRegistration } = messageProps;
    const onClick = e => {
      try {
        e.currentTarget.disabled = true;
        // Tell new service worker to activate immediately
        swRegistration.waiting.postMessage({ type: 'FORCE_ACTIVATE' });
      } catch {
        window.location.reload();
      }
    };
    return (
      <Button onClick={onClick} className="message-link">
        {getText('site-message-button-reload')}
      </Button>
    );
  }

  if (messageType === types.ALERT) {
    return (
      <Button onClick={onClose} className="message-link">
        {getText('site-message-button-close')}
      </Button>
    );
  }

  if (messageType === types.PROMO && messageProps.link != null) {
    return (
      <ExternalLink url={messageProps.link.url} className="message-link">
        {messageProps.link.text}
      </ExternalLink>
    );
  }

  return null;
};

const renderMessageIcon = ({ messageType }) => {
  if (messageType === types.PROMO) {
    return null;
  }

  if (messageType === types.ALERT || messageType === types.UPDATE_AVAILABLE) {
    return (
      <div className="icon">
        <Info />
      </div>
    );
  }

  return (
    <div className="icon">
      <Warning />
    </div>
  );
};

const SiteMessages = ({ messages, onClose }) => (
  <TransitionGroup className="site-messages" exit={false}>
    {messages.map(message => {
      const onCloseBound = onClose.bind(null, message);
      return (
        <CSSTransition
          key={message.id || message.clientId || message.message}
          classNames="item"
          timeout={1000}
        >
          <div
            className={classnames('item', {
              'item--negative': message && [
                types.SESSION_EXPIRED,
                types.LOGGED_IN_TO_OTHER_DEVICE,
                types.NAVIGATOR_OFFLINE,
                types.USER_LOGGED_OUT_BY_SERVER,
              ],
              'item--neutral-orange':
                message && message.messageType === types.ALERT,
              'item--neutral-blue':
                message && message.messageType === types.PROMO,
              'item--positive':
                message && message.messageType === types.UPDATE_AVAILABLE,
            })}
          >
            <ContentWrapper>
              <div className="item__content">
                <div className="flex">
                  {renderMessageIcon(message)}
                  <div className="text">
                    <Editorial smallText>
                      {renderMessageText(message)}{' '}
                      {renderMessageLink(message, onCloseBound)}
                    </Editorial>
                  </div>
                </div>
                {message.messageType !== types.UPDATE_AVAILABLE && (
                  <div className="buttons">
                    <CloseButton onClick={onCloseBound} large border={false} />
                  </div>
                )}
              </div>
            </ContentWrapper>
          </div>
        </CSSTransition>
      );
    })}
  </TransitionGroup>
);

SiteMessages.propTypes = {
  messages: arrayOf(
    shape({
      id: number,
      clientId: string,
      messageType: string.isRequired,
      message: string,
    }),
  ),
  onClose: func.isRequired,
};

export default SiteMessages;
