import classnames from 'classnames';
import PropTypes from 'prop-types';

import Checkmark from 'shared/components/icon/Checkmark';

import styles from './index.module.scss';

const CheckboxInput = ({
  checked,
  className,
  colorFill = false,
  disabled = false,
  labelText,
  onClick,
  onChange,
  responsiveSize = true,
  small = false,
}) => (
  <label
    className={classnames(className, styles.label, {
      [styles.disabled]: disabled,
      [styles.fill]: colorFill,
      [styles.responsiveSize]: responsiveSize,
    })}
    onClick={onClick}
  >
    <span
      className={classnames(styles.checkmarkWrapper, {
        [styles.small]: small,
      })}
    >
      {checked && <Checkmark />}
    </span>

    {labelText && <span className={styles.labelText}>{labelText}</span>}

    <input
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      type="checkbox"
    />
  </label>
);

CheckboxInput.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  colorFill: PropTypes.bool,
  disabled: PropTypes.bool,
  labelText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  responsiveSize: PropTypes.bool,
  small: PropTypes.bool,
};

export default CheckboxInput;
