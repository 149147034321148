import { lazy, Suspense } from 'react';

const SportComponent = lazy(() =>
  import(/* webpackChunkName: "sport" */ 'sport'),
);

const AsyncSportApp = () => (
  <Suspense fallback={<span />}>
    <SportComponent entryPoint="route" />
  </Suspense>
);

// TODO loader
export default AsyncSportApp;

// TODO loader
export const AsyncDialogs = props => (
  <Suspense fallback={<span />}>
    <SportComponent entryPoint="dialogs" {...props} />
  </Suspense>
);
