import Icon from './Icon';

const User = ({ className }) => (
  <Icon name="user" className={className} viewBox="0 0 24 24">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <path d="M24 0a24 24 0 1 0 24 24A24 24 0 0 0 24 0zM9.92 40.89a14 14 0 0 1 27.92.19 21.94 21.94 0 0 1-27.92-.19zM15 16.5a9 9 0 1 1 9 9 9 9 0 0 1-9-9zm24.68 22.91a16 16 0 0 0-11.06-12.94 11 11 0 1 0-9.33 0A16 16 0 0 0 8.1 39.18a22 22 0 1 1 31.58.23z" />
    </svg>
  </Icon>
);

// From overodds
export default User;
