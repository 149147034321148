import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import AsyncArticleApp from 'shared/components/AsyncArticleApp';
import AsyncAtgApp from 'shared/components/AsyncAtgApp';
import AsyncCalendarApp from 'shared/components/AsyncCalendarApp';
import AsyncLiveApp from 'shared/components/AsyncLiveApp';
import AsyncSportApp from 'shared/components/AsyncSportApp';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import JokerwizInfoPage from 'shared/components/JokerwizInfoPage';
import FullscreenLoader from 'shared/components/loader/FullscreenLoader';
import UiScale from 'shared/components/UiScale';
import AuthRoute from 'shared/containers/AuthRoute';
import CheckoutPage from 'shared/containers/CheckoutPage';
import Dialogs from 'shared/containers/Dialogs';
import ErrorBoundRoute from 'shared/containers/ErrorBoundRoute';
import MenuContainer from 'shared/containers/MenuContainer';
import SiteMessagesContainer from 'shared/containers/SiteMessagesContainer';
import SubscriptionsPage from 'shared/containers/SubscriptionsPage';
import {
  fetchUser,
  hideLoader,
  onLocationPathnameChange,
  updatePageVisibility,
} from 'shared/redux/actions';
import { ui, user } from 'shared/redux/selectors';
import Bugsnag from 'shared/utils/bugsnag';
import { load as loadText } from 'shared/utils/Text';
import { getUrl } from 'shared/utils/urls';

const mapStateToProps = state => ({
  loader: ui.getLoader(state),
  hasUserData: user.hasServerState(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      hideLoader,
      onLocationPathnameChange,
      updatePageVisibility,
      fetchUser,
    },
    dispatch,
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class App extends Component {
      state = {
        appInitialized: false,
      };

      handlePageVisibilityChange = () => {
        this.props.updatePageVisibility(document.hidden);
      };

      async componentDidMount() {
        const { hideLoader, updatePageVisibility, fetchUser } = this.props;

        try {
          await Promise.all([
            fetchUser(),
            loadText(),
            updatePageVisibility(document.hidden),
          ]);

          document.addEventListener(
            'visibilitychange',
            this.handlePageVisibilityChange,
          );

          this.setState({
            appInitialized: true,
          });
          hideLoader();
        } catch (error) {
          console.error(`🔥 ${error}`);
          Bugsnag.notify(error);
        }
      }

      UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
          nextProps.onLocationPathnameChange(nextProps.location.pathname);
        }
      }

      render() {
        const { appInitialized } = this.state;
        const { loader, hasUserData, location } = this.props;
        const { pathname } = location;

        const fullscreenLoader = (
          <FullscreenLoader pathname={pathname} {...loader} />
        );

        if (!appInitialized || !hasUserData) {
          return fullscreenLoader;
        }

        return (
          <Fragment>
            {fullscreenLoader}
            <Dialogs />
            <UiScale />
            <ErrorBoundary>
              <SiteMessagesContainer />
            </ErrorBoundary>
            <header className="page-top">
              <MenuContainer />
            </header>
            <Switch>
              <ErrorBoundRoute path="/" exact component={AsyncCalendarApp} />
              <ErrorBoundRoute path="/om-wiz" component={JokerwizInfoPage} />
              <ErrorBoundRoute
                path="/abonnemang"
                component={SubscriptionsPage}
              />
              <ErrorBoundRoute
                path="/checkout/:skuOrCampaignId"
                component={CheckoutPage}
              />
              <ErrorBoundRoute
                component={AsyncLiveApp}
                exact
                path={getUrl.live(':section?')}
              />
              {INCLUDE_TIPS && (
                <ErrorBoundRoute path="/tips" component={AsyncArticleApp} />
              )}
              <ErrorBoundRoute path="/nyheter" component={AsyncArticleApp} />
              <ErrorBoundRoute
                path="/trav/dela/:shareCode"
                component={AsyncAtgApp}
              />
              <ErrorBoundRoute
                path="/sport/dela/:shareCode"
                component={AsyncSportApp}
              />
              <ErrorBoundRoute
                path="/sport/bomben/:date"
                component={AsyncSportApp}
              />
              <ErrorBoundRoute path="/sport/bomben" component={AsyncSportApp} />
              <AuthRoute path="/trav/:betId" component={AsyncAtgApp} />
              <AuthRoute path="/sport/:betId" component={AsyncSportApp} />
              <ErrorBoundRoute path="/sport" component={AsyncSportApp} />
              <ErrorBoundRoute path="/overodds" component={AsyncSportApp} />

              {/* Expand share urls */}
              <Redirect
                exact
                path="/s/:shareCode"
                to="/sport/dela/:shareCode/resultat"
              />
              <Redirect exact path="/t/:shareCode" to="/trav/dela/:shareCode" />

              {/* Redirect legacy ATG share url */}
              <Redirect
                exact
                path="/share/:shareCode"
                to="/trav/dela/:shareCode"
              />

              {process.env.NODE_ENV === 'development' && (
                <Route
                  path="/demo"
                  component={
                    require('shared/components/AsyncComponentDemoApp').default
                  }
                />
              )}

              <ErrorBoundRoute path="/:slug" component={AsyncArticleApp} />
            </Switch>
          </Fragment>
        );
      }
    },
  ),
);
