export const SPORT_UPDATE = 'SPORT_UPDATE';
export const ATG_UPDATE = 'ATG_UPDATE';
export const MESSAGES_PRIVATE = 'MESSAGE_PRIVATE';
export const USER_DATA_UPDATE = 'USER_DATA_UPDATE';

export const LIVE_CHAT_MESSAGE = 'LIVE_CHAT_MESSAGE';
export const LIVE_CHAT_MESSAGE_DELETE = 'LIVE_CHAT_MESSAGE_DELETE';
export const LIVE_CHAT_MESSAGE_UPDATE = 'LIVE_CHAT_MESSAGE_UPDATE';

export const LIVE_ACTIVE_UPDATE = 'LIVE_ACTIVE_UPDATE';
