import { useEffect } from 'react';

import { LinkButton } from 'shared/components/button/Button';
import Editorial from 'shared/components/Editorial';
import JokerWizIcon from 'shared/components/icon/JokerWiz';
import ContentWrapper from 'shared/components/layout/ContentWrapper';
import PageBody from 'shared/components/layout/PageBody';
import PageMeta from 'shared/components/PageMeta';
import withContentMenu from 'shared/containers/withContentMenu';
import { getText } from 'shared/utils/Text';

import styles from './index.module.scss';

const JokerwizInfoPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageBody alignTo="menu">
      <PageMeta
        theme="article"
        title={getText('jokerwiz-explain-page-header')}
      />

      <ContentWrapper columnWidth="narrow">
        <header>
          <div className={styles.icon}>
            <JokerWizIcon />
          </div>

          <h1 className="article-header">
            {getText('jokerwiz-explain-page-header')}
          </h1>
        </header>

        <Editorial>
          <article>
            {IS_NO_SITE ? (
              <>
                <div className="editorial--preamble">
                  Wiz er en nyskapende nyhet hvor du bare trenger å velge
                  innsatsbeløpet – Wiz gjør resten! Hvert system er unikt og bak
                  rangeringene står landets beste tipstjenester.
                </div>

                <p>
                  Rangeringen i Wiz er laget ut ifra tipsene på Sveriges ledende
                  tipstjenester{' '}
                  <a
                    href="https://spelvarde.se"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Spelvärde.se
                  </a>
                  ,{' '}
                  <a
                    href="https://travogat.se"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Travögat.se
                  </a>
                  ,{' '}
                  <a
                    href="https://storavinster.se"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Storavinster.se
                  </a>
                  ,{' '}
                  <a
                    href="https://www.travrondenspel.se"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Travronden Spel
                  </a>{' '}
                  — samt unik statistik till omgången från{' '}
                  <a
                    href="https://travfakta.se"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Travfakta.se
                  </a>
                  . Wiz står til din tjeneste fra tidlig morgen dagen før hver
                  svensk V75-, V86-, GS75- og V64-omgang samt fredag lunsj
                  V65-omgang. Wiz presterer på sitt beste når alle tipstjenester
                  har publisert sine tips for den nåværende omgangen, så vent
                  gjerne med å bruke WIZ-systemet til etter disse tidspunktene:
                </p>
                <ul>
                  <li>Vanlige V75-omganger: fredager 15.30</li>
                  <li>Ekstra V75-omganger: 15:00 på løpsdagen</li>
                  <li>V86: 15:30 på løpsdagen</li>
                  <li>GS75: dagen før løpsdagen 20:30</li>
                  <li>V64: 16:30 på løpsdagen</li>
                  <li>V65: 09:30 på løpsdagen</li>
                </ul>

                <p>
                  Reduksjon av systemene gjøres ved hjelp av poengkrav og WIZ er
                  inkludert i Joker-abonnementet!
                </p>

                <h2>Bruksanvisning</h2>

                <ol>
                  <li>Velg omgang.</li>
                  <li>
                    Trykk ”Fortsett”, eller juster først de forhåndsinnstilte
                    verdiene for valgt risikonivå og hvor mye systemet maksimalt
                    kan koste å levere inn.
                  </li>
                  <li>
                    Trykk ”Fortsett” i den nye dialogboksen som kommer opp,
                    eller juster først de forhåndsinnstilte verdiene for antall
                    bankere som systemet skal inneholde, samt om du ønsker å
                    velge banker/bankere selv.
                  </li>
                  <li>
                    Trykk på ”Opprett system” i den nye dialogboksen som vises,
                    eller juster først den forhåndsinnstilte verdien for ønsket
                    systemoppsett.
                  </li>
                  <li>Systemet er klart for innlevering!</li>
                </ol>

                <p>
                  PS: Hvis du ønsker å gjøre endringer i systemet, lås opp
                  systemet ved å trykke på hengelåsen i menyen. Så er det bare å
                  endre som du ønsker.
                </p>

                <h2>Forklaring risikonivåer</h2>

                <p>
                  <strong>Lav</strong> — I tillegg til tipsene som er
                  tilgjengelig for omgangen hos tipsleverandørene, er Rikstotos
                  innsatsprosent på hestene også en faktor som har betydning for
                  rangeringen som lages. Tipsenere med høy innsatsprosent velges
                  som bankere, noe som betyr at sjansen for å få alle riktig er
                  maksimert. Ulempen er at sannsynligheten for at systemet gir
                  høy utbetaling er lavere enn ved andre valg.
                </p>

                <p>
                  <strong>Middels</strong> — I tillegg til tips tilgjengelig for
                  omgangen fra tipsleverandørene, er Rikstotos innsatsprosent på
                  hestene også en faktor som har en viss betydning for
                  rangeringen som lages. Tipsenere med «middels risiko» velges
                  som bankere, noe som betyr at sjansen for å få alle riktig er
                  større enn ved «høye» valg, men samtidig noe mindre enn ved
                  «lave» valg.
                </p>

                <p>
                  <strong>Høy</strong> — Rikstotos innsatsprosent på hestene er,
                  i motsetning til lavt og middels risikonivå, ikke en faktor
                  som tas i betraktning for rangeringen som skapes. Tipsenere
                  med større risiko velges som bankere, noe som betyr gode
                  forutsetninger for å vinne mye dersom systemet treffer.
                  Ulempen er at det er mindre sannsynlig å få alle riktig.
                </p>
              </>
            ) : (
              <>
                <div className="editorial--preamble">
                  Wiz är en innovativ nyhet där du bara behöver välja spelbelopp
                  – resten fixar Wiz! Varje system är unikt och bakom
                  rankingarna ligger landets främsta tipstjänster.
                </div>

                <p>
                  Rankingarna i Wiz skapas utifrån tipsen på landets ledande
                  tipstjänster{' '}
                  <a
                    href="https://spelvarde.se"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Spelvärde.se
                  </a>
                  ,{' '}
                  <a
                    href="https://travogat.se"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Travögat.se
                  </a>
                  ,{' '}
                  <a
                    href="https://storavinster.se"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Storavinster.se
                  </a>
                  ,{' '}
                  <a
                    href="https://www.travrondenspel.se"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Travronden Spel
                  </a>{' '}
                  — samt unik statistik till omgången från{' '}
                  <a
                    href="https://travfakta.se"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Travfakta.se
                  </a>
                  . Wiz står till din tjänst från tidig morgon dagen före varje
                  V75-, V86-, GS75- och V64-omgång samt även vissa V4-omgångar.
                  Som bäst presterar Wiz när samtliga tipstjänster publicerat
                  sina tips till aktuell omgång så vänta gärna med att ska ditt
                  WIZ-system till efter dessa tider:
                </p>
                <ul>
                  <li>Ordinarie V75-omgångar: fredagar 15:30</li>
                  <li>Extra V75-omgångar: 15:00 på tävlingsdagen</li>
                  <li>V86: 15:30 på tävlingsdagen</li>
                  <li>GS75: dagen före tävlingsdagen 20:30</li>
                  <li>V64: 16:30 på tävlingsdagen</li>
                  <li>V4: 10:00 på tävlingsdagen</li>
                </ul>

                <p>
                  Reducering av systemen sker med hjälp av poängkrav och WIZ
                  ingår i det ordinarie Jokerabonnemanget!
                </p>

                <h2>Instruktioner</h2>

                <ol>
                  <li>Välj omgång.</li>
                  <li>
                    Tryck på ”Gå vidare”, eller justera först de förinställda
                    värdena för vald risknivå och hur mycket systemet som mest
                    får kosta att lämna in.
                  </li>
                  <li>
                    Tryck på ”Gå vidare” i den nya dialogrutan som kommer upp,
                    eller justera först de förinställda värdena för antal spikar
                    som systemet ska innehålla, samt om du vill välja
                    spik/spikar själv.
                  </li>
                  <li>
                    Tryck på ”Skapa system” i den nya dialogrutan som kommer
                    upp, eller justera först det förinställda värdet för önskat
                    systemupplägg.
                  </li>
                  <li>Systemet är klart för inlämning!</li>
                </ol>

                <p>
                  PS: Om du skulle önska göra någon förändring i systemet låser
                  du upp systemet genom att trycka på hänglåset i menyn. Sedan
                  är det bara att ändra som du önskar.
                </p>

                <h2>Förklaring risknivåer</h2>

                <p>
                  <strong>Låg</strong> — Förutom de tips som finns tillgängliga
                  till omgången hos tipsleverantörerna så är även ATG:s
                  insatsprocent på hästarna en faktor som har betydelse för den
                  ranking som skapas. Tipsettor med hög insatsprocent väljs som
                  spikar, vilket innebär att chansen till att få alla rätt är
                  maximerad. Minuset är att sannolikheten för att systemet ska
                  ge storvinst är lägre än vid övriga val.
                </p>

                <p>
                  <strong>Medel</strong> — Förutom de tips som finns
                  tillgängliga till omgången hos tipsleverantörerna så är även
                  ATG:s insatsprocent på hästarna en faktor som har en viss
                  betydelse för den ranking som skapas. Tipsettor med
                  ”medelrisk” väljs som spikar, vilket innebär att chansen till
                  att få alla rätt är högre än vid val ”hög”, men samtidigt
                  något lägre än vid val ”låg”.
                </p>

                <p>
                  <strong>Hög</strong> — ATG:s insatsprocent på hästarna är till
                  skillnad mot risknivå låg och medel ingen faktor som räknas in
                  för den ranking som skapas. Tipsettor med högre risk väljs som
                  spikar, vilket innebär goda förutsättningar för att vinna
                  stort om systemet slår in. Nackdelen är att du mer sällan
                  kommer att få alla rätt.
                </p>
              </>
            )}

            <LinkButton to="/" className={styles.backButton}>
              {getText('jokerwiz-explain-home')}
            </LinkButton>
          </article>
        </Editorial>
      </ContentWrapper>
    </PageBody>
  );
};

export default withContentMenu(JokerwizInfoPage);
