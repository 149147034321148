import config from 'config';
import get from 'lodash/get';
import { RSAA } from 'redux-api-middleware';

const middleware = store => next => action => {
  const callAPI = action[RSAA];

  // Check if this action is a redux-api-middleware action
  if (callAPI) {
    if (
      !/^((http|https|ftp):\/\/)/i.test(callAPI.endpoint) &&
      ![
        config.trsportApi,
        config.traisApi,
        config.vercelApi,
        config.authApi,
        config.checkoutApi,
      ].some(api => callAPI.endpoint.startsWith(api))
    ) {
      callAPI.endpoint = `${config.jokerApi}/${callAPI.endpoint}`;
    }

    // Default to GET if method is undefined
    if (callAPI.method == null) {
      callAPI.method = 'GET';
    }

    // Default to set credentials to send cross domain cookies
    if (callAPI.credentials == null) {
      callAPI.credentials = 'include';
    }

    // Set headers to empty object if undefined
    if (callAPI.headers == null) {
      callAPI.headers = {};
    }

    // Set Content-Type to application/json if Content-Type does not already have a value
    if (get(callAPI.headers, 'Content-Type', null) == null) {
      callAPI.headers['Content-Type'] = 'application/json';
    }

    if (
      get(callAPI.headers, 'Content-Type', null) === 'application/octet-stream'
    ) {
      //If file upload we let the browser handle content-type and multipart delimiters.
      delete callAPI.headers['Content-Type'];
    }

    if (process.env.NODE_ENV === 'development') {
      const { bailout, method, endpoint } = callAPI;
      const willBailout =
        (typeof bailout === 'boolean' && bailout) ||
        (typeof bailout === 'function' && bailout(store.getState()));
      if (!willBailout) {
        console.info(`${method}: ${endpoint}`);
      }
    }
  }

  // Pass the FSA to the next action
  return next(action);
};

export default middleware;
