import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import config from 'config';
import * as React from 'react';

Bugsnag.start({
  apiKey: config.bugsnagApiKey,
  appVersion: config.appVersion,
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['production'],
  enabledBreadcrumbTypes: [
    'navigation',
    'request',
    'process',
    'user',
    'state',
    'error',
    'manual',
  ],
  maxBreadcrumbs: 30,
  plugins: [new BugsnagPluginReact(React)],

  onError: event => {
    // Log only when version is set
    if (config.appVersion == null) {
      return false;
    }

    const { originalError, errors } = event;

    // Discard error caused by ad script. This is probably an unhandled promise
    // rejection that was caused by tracking protection in the browser.
    if (
      originalError.target != null &&
      originalError.target.tagName === 'SCRIPT' &&
      originalError.target.src.includes('gpt.js')
    ) {
      return false;
    }

    // Custom grouping of web socket jsonp errors because they are now grouped
    // by "Method, file and line number" which is different for each of
    // these, thus we get no grouping at all.
    if (
      errors.some(
        error =>
          error.errorMessage.includes('_jp') ||
          error.errorMessage.includes('c is not defined'),
      )
    ) {
      event.groupingHash = 'websocket jsonp error';
    }
  },
});

export { default } from '@bugsnag/js';
