import { createElement } from 'react';

import DialogPortal from 'shared/components/dialog/DialogPortal';

export function createPortal(componentType, componentProps, key, className) {
  return (
    <DialogPortal key={key} dialogKey={key} className={className}>
      {(closeHandler, ensureFit) =>
        createElement(componentType, {
          ...componentProps,
          closeHandler,
          ensureFit,
          dialogKey: key,
        })
      }
    </DialogPortal>
  );
}
