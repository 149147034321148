const apiMap = {
  default: process.env.REACT_APP_BACKEND_DOMAIN,
  7071: 'https://joker-app2.trdev.se:7071',
  7072: 'https://joker-app2.trdev.se:7072',
  7073: 'https://joker-app2.trdev.se:7073',
  7074: 'https://joker-app2.trdev.se:7074',
};

const getBackendPathForPort = port =>
  Object.keys(apiMap).includes(port) ? `app-${port}/rest` : 'app/rest';

module.exports = {
  apiMap,
  getBackendPathForPort,
};
