import config from 'config';

export function getBaseUrl() {
  return window.location.origin
    ? window.location.origin
    : `${window.location.protocol}//${window.location.host}`;
}

export function toAbsoluteUrl(path) {
  if (path == null) {
    return null;
  }

  if (/^https?:\/\/[^/]+/.test(path)) {
    return path;
  }

  return getBaseUrl() + (path.indexOf('/') !== 0 ? '/' : '') + path;
}

export const getUrl = {
  live: section => `/live/${section === 'active' ? '' : section || ''}`,
  news: () => `/nyheter`,
  instructions: () =>
    IS_NO_SITE ? '/instruksjonsfilmer' : '/instruktionsfilmer',
  userpages: () => config.userpagesUrl,
  editLiveAlias: () => `${config.userpagesUrl}/joker`,
  login: (returnHref = window.location.href) =>
    `${config.authUrl}?ReturnUrl=${encodeURI(toAbsoluteUrl(returnHref))}`,
  logout: (returnHref = window.location.href) =>
    `${config.authUrl}/logout?ReturnUrl=${encodeURI(
      toAbsoluteUrl(returnHref),
    )}`,
  register: (returnHref = window.location.href) =>
    `${config.authUrl}/register?ReturnUrl=${encodeURI(
      toAbsoluteUrl(returnHref),
    )}`,
};
